@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import '_variables.scss';

span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
}

.header {
    font-size: 34px;
    line-height: 51px;
    color: $color-blue;

    &.welcome {
        font-weight: 500;
        font-size: 48px;

    }
}

.description {
    font-size: 18px;
    line-height: 27px;
    color: $color-gray;
}