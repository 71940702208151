@import '_variables.scss';

.brown {
    background-color: #F0EAD9;
}

.light-brown {
    background-color: #F7F4EC;
}

.blue {
    background-color: $color-blue;
}

.light-blue {
    background-color: $color-lightblue;
}

.gray {
    background-color: #403F3F
}

.light-gray {
    background-color: #A1A1A1;
}