@import './theme';
@import '_layout.scss';
@import '_fonts.scss';
@import '_buttons.scss';
@import '_forms.scss';
@import '_variables.scss';
@import 'backoffice-buttons.scss';
@import 'backoffice-confirmation-dialog.scss';

@import 'portal_v1/_colors.scss';
@import 'portal_v1/_fonts.scss';
@import 'portal_v1/_variables.scss';

html, body {
  height: 100%;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }